import React, { useState, useRef, useEffect } from 'react';
import ReactImageGallery from 'react-image-gallery';
import Modal from 'react-modal';
import Slider from 'react-slick';

const ImageGallery=(props)=>{
	const {images} = props.data;


	let getImages = []
	images.map(image=>{
		getImages.push({ 
			original: !!image ? !!image.image.childImageSharp ? image.image.childImageSharp.fluid.src : image.image : null,
			thumbnail: !!image ? !!image.image.childImageSharp ? image.image.childImageSharp.fluid.src : image.image : null})
	})

	
	return(
		<div>
			<ReactImageGallery items={getImages}/>
		</div>
	)
}

export default ImageGallery;