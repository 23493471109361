import React from "react";
import OneColumnContent from "../columns/OneColumnContent";
import TwoColumnContent from "../columns/TwoColumnContent";
import ThreeColumnContent from "../columns/ThreeColumnContent";

const Columns = props => {
	return (
    <>

      {/* One Column */}
      {props.content.columnCount === 'one' &&
        <OneColumnContent
          columns={props.content.columns}
          langKey={props.langKey}
          reusable={props.reusable}
        />
      }

      {/* Two Columns */}
      {props.content.columnCount === 'two' &&
        <TwoColumnContent
          columns={props.content.columns}
          langKey={props.langKey}
          reusable={props.reusable}
        />
      }

      {/* Three Columns */}
      {props.content.columnCount === 'three' &&
        <ThreeColumnContent
          columns={props.content.columns}
          langKey={props.langKey}
          reusable={props.reusable}
        />
      }

    </>
	);
};

export default Columns;
