import React, { useEffect, useState } from "react";
import remark from "remark";
import parse from "remark-parse";
import remarkToreact from 'remark-react';
import remarkAttr from 'remark-attr';
import { iteratee } from "lodash";



const TimezoneTable = props => {
  //load all timezone data
  const timezoneData = {
    aedt: require("../../../functions/data/timezones/australia-aedt-timezone.json"),
    aest: require("../../../functions/data/timezones/australia-aest-timezone.json"),
    albertaMDT: require("../../../functions/data/timezones/alberta-mdt-timezone.json"),
    albertaMST: require("../../../functions/data/timezones/alberta-mst-timezone.json"),
    costaricaCSTCR: require("../../../functions/data/timezones/costarica-cst-cr-timezone.json"),
    cet: require("../../../functions/data/timezones/europe-cet-timezone.json"),
    india: require("../../../functions/data/timezones/india-timezone.json"),
    naCDT: require("../../../functions/data/timezones/north-american-cdt-timezone.json"),
    naCST: require("../../../functions/data/timezones/north-american-cst-timezone.json"),
    naEDT: require("../../../functions/data/timezones/north-american-edt-timezone.json"),
    naEST: require("../../../functions/data/timezones/north-american-est-timezone.json"),
    naMDT: require("../../../functions/data/timezones/north-american-mdt-timezone.json"),
    naMST: require("../../../functions/data/timezones/north-american-mst-timezone.json"),
    naPDT: require("../../../functions/data/timezones/north-american-pdt-timezone.json"),
    naPST: require("../../../functions/data/timezones/north-american-pst-timezone.json"),
    panamaEST: require("../../../functions/data/timezones/panama-est-timezone.json"),
    ph: require("../../../functions/data/timezones/ph-hk-china-timezone.json"),
    utc: require("../../../functions/data/timezones/utc-timezone.json"),
    
  }
 
  const Rows = (props)=>{
    const rows = [];
    // retrieve the columns to be used
    const timezoneColumns = props.data.data.timezoneColumns;

    // This builds the rows to arrange the different timezones into their respective columns
    for(let row=0;row<=23;row++){
      const getRow = [];
      
      timezoneColumns.map((item) => {

        if(!item.column ){
           getRow.push({"time": "", "backgroundColor":"", "textColor": ""});
        }
        else if(item.column === "numbering" ){
           getRow.push({"time": row+1, "backgroundColor":"", "textColor": ""});
        }
        else{
          const data = timezoneData[item.column].times[row];
          getRow.push({"time": data.time, "backgroundColor":data.backgroundColor, "textColor": data.textColor});
        }
        
       
      })
      rows.push(getRow)
    }
    return(
      rows.map(row=>{
        return(
          <tr>
            {row.map(item=>{
              return(
                <td style={{backgroundColor: (item.backgroundColor === "white")? "":item.backgroundColor, 
                  color: item.textColor}}>
                  {item.time}
                </td>
              )
            })}
          </tr>
        )
      })
    )
  }

  return (
    <div
      style={{
        textAlign: 'center',
        paddingBottom: '1.5rem'
      }}>
      <p className='is-size-9 has-text-weight-bold'>
        {props.title}
      </p>
      <div className={`${props.data.isScrollable ? "table-container" : null}`}>
        <table className="table is-striped is-fullwidth">
          {props.data.header &&
            <thead>
              <tr>
                {props.data.header.map(header => (
                  <th style={{backgroundColor: `${header.color}`, color: `${header.textColor}`}}>
                    {header.cell !== null &&
                      <>
                        {remark().use(parse).use(remarkAttr).use(remarkToreact).processSync(header.cell).contents}
                      </>
                    }
                  </th>
                ))}
              </tr>
            </thead>
          }
          {props.data.footer &&
            <tfoot>
              <tr>
                {props.data.footer.map(footer => (
                  <th style={{backgroundColor: `${footer.color}`, color: `${footer.textColor}`}}>
                    {footer.cell !== null &&
                      <>
                        {remark().use(parse).use(remarkAttr).use(remarkToreact).processSync(footer.cell).contents}
                      </>
                    }
                  </th>
                ))}
              </tr>
            </tfoot>
          }
          <tbody>
            <Rows data={props}></Rows>
          </tbody>
        </table>
      {props.note &&
        <p>
          {props.note}
        </p>
      }
      </div>
    </div>
  );
};

export default TimezoneTable;