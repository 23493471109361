import React, { useState } from 'react';
import Table from "mcc-cms/columns/Table";
import TimezoneTable from './TimezoneTable';

function Tabs(props){
    const [activeTab, setActiveTab] = useState(0);
    
    const handleTabClick = (index) => {
        setActiveTab(index);
    };
    
  return (
    <>
      <div className="tab">
      <div >
        {props.data.tabs.map((tab, index) => (
          <button
            key={index}
            className={index === activeTab ? 'active' : ''}
            onClick={() => handleTabClick(index)}
          >
            {tab.tabTitle}
          </button>
        ))}
      </div>
      <div className="tab-content">
        <div
          className="content"
          style={{paddingBottom: "1.5rem"}}
        >
        {console.log(props.data.tabs[activeTab].tabContents)}
        {
          props.data.tabs[activeTab].tabContents.map((tab)=>(
          <>
            {
              tab.type ==="table" &&
              <Table
                  title={props.data.tabs[activeTab].tabContents.title !== null ? props.data.tabs[activeTab].tabContents.title : null}
                  data={tab}></Table>
            }
            {
              tab.type ==="timezoneTable" &&
              <TimezoneTable
                title={props.data.tabs[activeTab].tabContents.title !== null ? props.data.tabs[activeTab].tabContents.title : null}
                data={tab}
              />
            }
          </>
          ))
        }
          
        </div>
      
      </div>
    </div>
    </>
  );
};

export default Tabs;
